import { Cookies } from 'react-cookie';
import _ from 'lodash';
import JwtAuthentication from './jwtAuthentication';
import { CCC_ROLE } from '@constants/role';
import { SCOPE } from '@constants/scope';

const cookies = new Cookies();

export const setToken = token => {
  cookies.set('id_token', token, { path: '/' });
};

export const getToken = () => cookies.get('id_token');

export const removeToken = () => {
  cookies.remove('id_token', { path: '/' });
};

export const setTokenRole = token => {
  cookies.set('role', token, { path: '/' });
};

export const getTokenClaims = () => {
  if (typeof window === 'undefined') return '';

  const accessToken = getToken();
  const tokenPayload = accessToken.split('.')[1];
  return JSON.parse(Buffer.from(tokenPayload, 'base64').toString());
};

export const setCompanySession = company => {
  // guard browser before using localstorage object
  if (!process.browser) {
    return;
  }

  localStorage.setItem('companySession', JSON.stringify(company));
};

export const getCompanySession = () => {
  // guard browser before using localstorage object
  if (!process.browser) {
    return {};
  }

  const companySessionString = localStorage.getItem('companySession');

  if (!companySessionString) {
    return {};
  }

  return JSON.parse(companySessionString);
};

export const getTokenRole = () => cookies.get('role');

export const getRoleFlag = () => {
  // guard browser before using localstorage object
  if (!process.browser) {
    return false;
  }

  const loginToken = getToken();
  return loginToken && loginToken !== 'undefined';
};

export const getRoles = () => {
  if (!getRoleFlag()) {
    return false;
  }

  const accessToken = getToken();
  const tokenPayload = accessToken.split('.')[1];
  return JSON.parse(Buffer.from(tokenPayload, 'base64').toString())?.roles;
};

export const getRoleByScope = scope => {
  if (!getRoleFlag()) {
    return '';
  } else if (_.isEmpty(scope)) {
    return '';
  }

  const accessToken = getToken();
  const tokenPayload = accessToken.split('.')[1];
  const roles = JSON.parse(Buffer.from(tokenPayload, 'base64').toString())
    .roles;

  return _.findLast(roles, role => {
    return _.startsWith(role, scope + '.');
  });
};

export const removeTokenRole = () => {
  cookies.remove('role', { path: '/' });
};

export const isAuth = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken();

  const localToken = JwtAuthentication.checkExpirity(token);

  return !!token && !!localToken.token; // handwaiving here
};

export const getUserId = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  const token = getToken();

  const tokenPayload = token?.split('.')[1];

  return (
    tokenPayload &&
    JSON.parse(Buffer.from(tokenPayload, 'base64').toString()).user_id
  );
};

export const getUserEmail = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  const token = getToken();

  const tokenPayload = token?.split('.')[1];

  return (
    tokenPayload &&
    JSON.parse(Buffer.from(tokenPayload, 'base64').toString()).email
  );
};

export const isCCCAdminOrOwner = () => {
  return _.includes(
    [CCC_ROLE.owner, CCC_ROLE.admin],
    getRoleByScope(SCOPE.CORPORATE_CREDIT_CARD)
  );
};
