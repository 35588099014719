export const COMPANY_ROLE = {
  OWNER: 'company_owner',
  ADMIN: 'company_admin',
  CARD: 'company_card'
};

export const CCC_ROLE = {
  owner: 'ccc.company_owner',
  admin: 'ccc.company_admin',
  card: 'ccc.company_card'
};

export const SPEND_ROLE = {
  owner: 'spend.owner',
  admin: 'spend.admin',
  user: 'spend.user'
};

export const REIMBURSEMENT_ROLE = {
  owner: 'reimbursement.owner',
  finance: 'reimbursement.finance',
  manager: 'reimbursement.manager',
  employee: 'reimbursement.employee'
};

const EMPTY_ROLE_OPTION = { label: 'No Role Assigned', value: null };

export const COMPANY_ROLE_OPTIONS = [
  EMPTY_ROLE_OPTION,
  { label: 'Admin', value: CCC_ROLE.admin },
  { label: 'User', value: CCC_ROLE.card }
];

// owner can promote others to owner
export const COMPANY_ROLE_OPTIONS_OWNER = [
  ...COMPANY_ROLE_OPTIONS,
  { label: 'Owner', value: CCC_ROLE.owner }
];

export const SPEND_ROLE_OPTIONS = [
  EMPTY_ROLE_OPTION,
  { label: 'Owner', value: SPEND_ROLE.owner },
  { label: 'Admin', value: SPEND_ROLE.admin },
  { label: 'User', value: SPEND_ROLE.user }
];

export const REIMBURSEMENT_ROLE_OPTIONS = [
  EMPTY_ROLE_OPTION,
  { label: 'Owner', value: REIMBURSEMENT_ROLE.owner },
  { label: 'Finance', value: REIMBURSEMENT_ROLE.finance },
  { label: 'Employee', value: REIMBURSEMENT_ROLE.employee }
];

export const ROLE_TOOLTIP = {
  [CCC_ROLE.owner]:
    'Owner Role can access card application data, transaction data, and billing data for all of the cards in your company and can also manage access for other Owner/Admin/User.',
  [CCC_ROLE.admin]:
    'Admin Role can access card application data, transaction data, and billing data for all of the cards in your company and can also manage access for other Admin/User.',
  [CCC_ROLE.card]:
    'User Role can only access card application data, transaction data, and billing data for their own assigned cards, and cannot manage access for other Owner/Admin/User.',
  [SPEND_ROLE.owner]:
    'Owner Role can create, approve, and disburse transactions, and all access to settings & user management.',
  [SPEND_ROLE.admin]:
    'Admin Role can create & approve transactions, view all transactions that is created within the dashboard, and limited access to settings.',
  [SPEND_ROLE.user]:
    'User Role can create & approve transactions, but has no access to settings, and can not view all transactions.',
  [REIMBURSEMENT_ROLE.finance]:
    'Finance Role can create, approve, and disburse reimbursement transactions, and access to settings',
  [REIMBURSEMENT_ROLE.employee]:
    'Employee Role can create & approve transactions, but has no access to settings, and can not view historical transactions.'
};

export const COLOR_BY_ROLE = {
  [REIMBURSEMENT_ROLE.owner]: 'blue',
  [REIMBURSEMENT_ROLE.finance]: 'green',
  [REIMBURSEMENT_ROLE.employee]: 'gold',
  [CCC_ROLE.owner]: 'blue',
  [CCC_ROLE.admin]: 'green',
  [CCC_ROLE.card]: 'gold',
  [SPEND_ROLE.owner]: 'blue',
  [SPEND_ROLE.admin]: 'green',
  [SPEND_ROLE.user]: 'gold'
};

export const MANAGEMENT_ROLE = {
  hr: 'management.hr'
};

export const ACCOUNT_WITH_SUBORDINATE = {
  IS_MANAGER: 'IS_MANAGER'
};

// Role reimbursement.employee previously can only be access in mobile app. Now it can be access in web, but with limited route access.
export const ROLES_WITHOUT_REIMBURSEMENT_EMPLOYEE = Object.values({
  ...COMPANY_ROLE,
  ...CCC_ROLE,
  ...SPEND_ROLE,
  ...MANAGEMENT_ROLE,
  ...REIMBURSEMENT_ROLE
}).filter(role => role !== REIMBURSEMENT_ROLE.employee);
